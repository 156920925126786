import { Col, Row, Card, CardBody, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import SideNav from '../components/sidenav';
import AuthContext from '../contexts/auth';
import { HeadFC } from 'gatsby';
import React, { useState } from 'react';

import './index.scss';

const AdminProfileSettings = () => {
  const [userName, setUserName] = useState('');

  const onSubmitProfileSettingsFrom = (e: any) => {
    e.preventDefault();
  }

  const onChangeInput = (nameTyped: string) => {
    setUserName(nameTyped);
  }

  return (
    <AuthContext>
      <div className="admin-container d-flex flex-row w-100">
        <SideNav
          activeMenu='noticias'
        />
        <Row className="admin-page-content d-flex flex-column align-items-center">
          <Col md={11}>
            <Card className="w-100 p-4 mb-5">
              <CardBody>
                <h4>Configuración de perfil</h4>
                <Form onSubmit={(e) => onSubmitProfileSettingsFrom(e)}>
                  <Row>
                    <Col md={4}>
                      <FormGroup className="d-flex justify-content-center align-items-center">
                        <Label for="login_name_text" className="mr-2">
                          Nombre
                        </Label>
                        <Input
                          type="text"
                          id="login_name_text"
                          value={userName}
                          onChange={(e) => onChangeInput(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Button
                          type="submit"
                          color="success"
                          className="w-100"
                        >
                          Actualizar
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </AuthContext>
  );
}

export default AdminProfileSettings;

export const Head: HeadFC = () => <title>LaBotana : Admin - Noticias</title>
